import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import demonstration from '../../../../public/images/main-section/demonstration-background.svg';
import { Text } from '../../../UI';
import theme from '../../../UI/theme';

const text =
  'Российская современная IT-компания VEGA предоставляет широкий спектр услуг по удаленной разработке и решает различные задачи независимо от уровня сложности и масштаба';

const DemonstrationWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    display: flex;
    width: 100%;
    height: 573px;
    margin-top: 19px;
    margin-bottom: 153px;

    background: url(${demonstration}) no-repeat;
    background-size: cover;
    object-fit: cover;

    ${mediaQueries.xl} {
      height: 470px;
      margin-top: 0;
      margin-bottom: 130px;
    }

    ${mediaQueries.l} {
      height: 470px;
      margin-top: 0;
      margin-bottom: 100px;
    }

    ${mediaQueries.m} {
      height: 326px;
      margin-top: 0;
      margin-bottom: 75px;
    }

    ${mediaQueries.s} {
      height: 255px;
      margin-top: 0;
      margin-bottom: 60px;
    }

    ${mediaQueries.xs} {
      height: 127px;
      margin-top: 25px;
      margin-bottom: 43px;
    }
  `,
);

const DemonstrationText = styled(Text)(
  ({ theme: { mediaQueries } }) => css`
    position: absolute;
    z-index: 3;
    display: flex;
    width: 1483px;
    margin: 286px 111px 46px 206px;
    color: ${theme.colors.black};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;

    ${mediaQueries.xl} {
      max-width: 1185px;
      margin: 234.5px 5% 37.6px 15%;
      font-size: 42px;
      line-height: 49px;
    }

    ${mediaQueries.l} {
      max-width: 900px;
      margin: 282.5px 0 37.6px 11.5%;
      font-size: 30px;
      line-height: 37px;
    }

    ${mediaQueries.m} {
      max-width: 700px;
      margin: 197.4px 0 16.6px 7.12%;
      font-size: 25px;
      line-height: 28px;
    }

    ${mediaQueries.s} {
      max-width: 480px;
      margin: 142.2px 0 12.8px 6.6%;
      font-size: 18px;
      line-height: 20px;
    }

    ${mediaQueries.xs} {
      max-width: 296px;
      height: 66px;
      margin: 45px 36px 9px 28px;
      color: ${theme.colors.text.primary};
      font-size: 14px;
      line-height: 14.63px;
    }
  `,
);

const DemonstrationSection: FC = () => {
  return (
    <DemonstrationWrapper>
      <DemonstrationText>{text}</DemonstrationText>
    </DemonstrationWrapper>
  );
};

export { DemonstrationSection as default };

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import vegaAdaptive from '../../../../public/images/header-section/vega-adaptive.svg';

const HeaderWrapper = styled.header(
  ({ theme: { mediaQueries } }) => css`
    display: flex;
    align-items: end;
    justify-content: flex-start;
    width: 100%;
    min-height: 211px;
    padding: 64px 0 49px 151px;

    ${mediaQueries.m} {
      width: 80%;
      min-height: 105px;
      padding: 25px 0 20px 40px;
    }

    ${mediaQueries.s} {
      min-height: 95px;
      padding: 25px 0 20px 40px;
    }

    ${mediaQueries.xs} {
      min-width: 328px;
      min-height: 43px;
      padding: 34px 16px 25px 16px;
    }
  `,
);

const VegaLogo = styled.img(
  ({ theme: { mediaQueries } }) => css`
    width: 387px;
    height: 98px;

    ${mediaQueries.m} {
      width: 220px;
      height: 60px;
    }

    ${mediaQueries.s} {
      width: 190px;
      height: 53px;
    }

    ${mediaQueries.xs} {
      width: 132px;
      height: 43px;
    }
  `,
);

const TechnologyText = styled.div(
  ({ theme: { mediaQueries, colors, fontSizes } }) => css`
    min-width: 566px;
    min-height: 38px;
    padding-left: 58px;
    color: ${colors.black};
    font-family: Montserrat, serif;
    font-size: ${fontSizes[9]}px;
    font-weight: 600;

    ${mediaQueries.m} {
      min-width: 250px;
      padding-left: 38px;
      font-size: ${fontSizes[8]}px;
    }

    ${mediaQueries.s} {
      min-width: 200px;
      padding-left: 35px;
      font-size: ${fontSizes[7]}px;
    }

    ${mediaQueries.xs} {
      min-width: 144px;
      min-height: 42px;
      padding-left: 32px;
      font-size: ${fontSizes[3]}px;
      line-height: 140%;
    }
  `,
);

const VegaHeader: FC = () => {
  return (
    <HeaderWrapper>
      <VegaLogo src={vegaAdaptive} alt='' />
      <TechnologyText>Технологический партнер</TechnologyText>
    </HeaderWrapper>
  );
};

export { VegaHeader as default };
